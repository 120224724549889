
import * as Components from "./Chinabuffet"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "chinabuffet"
}
